<template>
    <div class="popular-places section">
        <h2 class="section__heading">
            {{ heading }}
        </h2>

        <div class="wrap">
            <!-- Countries -->
            <div class="popular-places__tiles" :class="tilesClasses">
                <div
                    v-for="(country, idx) in sortedCountries"
                    :key="`${country}-${idx}`"
                    class="popular-places__tile"
                    :class="[country.columns > 1 ? `popular-places__tile--columns-${country.columns}` : '']"
                >
                    <LocalizedLink v-lazy-load-image="country.src" :to="localePath(country.to)">
                        <span class="popular-places__tile-title">{{ country.title }}</span>
                    </LocalizedLink>
                </div>
            </div>

            <!-- Cities -->
            <template v-if="showCities">
                <h3 class="popular-places__cities-heading">
                    {{ citiesHeading }}
                </h3>
                <div class="popular-places__cities-wrapper">
                    <LocalizedLink
                        v-for="city in filteredPageChildren"
                        :key="city.id"
                        :to="city.path"
                        class="popular-places__cities-column"
                    >
                        {{ city.breadcrumbTitle }}
                    </LocalizedLink>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PopularPlaces',
    data() {
        return {
            availableLocales: ['se', 'no', 'fi', 'dk', 'fi-sv', 'ch', 'fr'],
            currentPage: {},
            basePath: `/${this.$t('common.motorhomes_page_path')}`,
        };
    },
    computed: {
        ...mapGetters(['cmsPage']),
        tilesClasses() {
            return {
                'popular-places__tiles--no-bottom-margin': !this.showCities,
            };
        },
        heading() {
            return this.$i18n.locale === 'ch'
                ? this.$t('sections.popular_places.heading_ch')
                : this.$t('sections.popular_places.heading');
        },
        citiesHeading() {
            return this.$i18n.locale === 'ch'
                ? this.$t('sections.popular_places.cities_heading_ch')
                : this.$t('sections.popular_places.cities_heading');
        },
        countries() {
            const denmark =
                this.$i18n.locale === 'de' || this.$i18n.locale === 'ch'
                    ? 'daenemark'
                    : this.$t('localization.countries.DK').toLowerCase();
            const chPath =
                this.$i18n.locale === 'ch' || this.$i18n.locale === 'fr'
                    ? `${this.basePath}`
                    : `${this.basePath}/${this.$t('localization.countries.CH').toLowerCase()}`;

            const countries = [
                {
                    lang_codes: ['se'],
                    columns: 2,
                    title: this.$t('localization.countries.SE'),
                    to: {
                        path: `${this.basePath}/${this.replaceSpecialCharacter(
                            this.$t('localization.countries.SE').toLowerCase()
                        )}`,
                    },
                    src: require('~/assets/images/sweden-img.webp'),
                },
                {
                    lang_codes: ['no'],
                    title: this.$t('localization.countries.NO'),
                    to: {
                        path: `${this.basePath}/${this.replaceSpecialCharacter(
                            this.$t('localization.countries.NO').toLowerCase()
                        )}`,
                    },
                    src: require('~/assets/images/norway-img.webp'),
                },
                {
                    lang_codes: ['fi', 'fi-sv'],
                    title: this.$t('localization.countries.FI'),
                    to: {
                        path: `${this.basePath}/${this.$t('localization.countries.FI').toLowerCase()}`,
                    },
                    src: require('~/assets/images/finland-img.webp'),
                },
                {
                    lang_codes: ['dk'],
                    title: this.$t('localization.countries.DK'),
                    to: {
                        path: `${this.basePath}/${denmark}`,
                    },
                    src: require('~/assets/images/denmark-img.webp'),
                },
                {
                    lang_codes: ['ch', 'fr'],
                    title: this.$t('localization.countries.CH'),
                    to: {
                        path: chPath,
                    },
                    src: require('~/assets/images/switzerland-img.webp'),
                },
            ];

            if (['se', 'en', 'ch'].includes(this.$i18n.locale)) {
                countries.push(
                    {
                        lang_codes: [],
                        columns: 2,
                        title: this.$t('localization.countries.US'),
                        to: {
                            path: `${this.basePath}/${this.$t('common.usa_page_path')}`,
                        },
                        src: require('~/assets/images/usa-img.webp'),
                    },
                    {
                        lang_codes: [],
                        title: this.$t('localization.countries.CA'),
                        to: {
                            path: `${this.basePath}/${this.$t('localization.countries.CA').toLowerCase()}`,
                        },
                        src: require('~/assets/images/canada-img.webp'),
                    },
                    {
                        lang_codes: [],
                        title: this.$t('localization.countries.AU'),
                        to: {
                            path: `${this.basePath}/${this.$t('localization.countries.AU').toLowerCase()}`,
                        },
                        src: require('~/assets/images/australia-img.webp'),
                    },
                    {
                        lang_codes: [],
                        columns: 2,
                        title: this.$t('localization.countries.NZ'),
                        to: {
                            path: `${this.basePath}/${this.$t('common.new_zealand_page_path')}`,
                        },
                        src: require('~/assets/images/new-zealand-img.webp'),
                    }
                );
            }

            return countries;
        },
        sortedCountries() {
            const countries = JSON.parse(JSON.stringify(this.countries));
            // prioritize country with current language locale
            countries.sort(
                (a, b) => b.lang_codes.includes(this.$i18n.locale) - a.lang_codes.includes(this.$i18n.locale)
            );

            if (this.availableLocales.includes(this.$i18n.locale)) {
                countries[0].to = { path: this.basePath };
            }

            return countries;
        },
        availableLocalesNames() {
            return [
                this.$t('localization.countries.SE'),
                this.$t('localization.countries.NO'),
                this.$t('localization.countries.FI'),
                this.$t('localization.countries.DK'),
                this.$t('localization.countries.CH'),
                this.$t('localization.countries.FR'),
            ];
        },
        filteredPageChildren() {
            if (!this.currentPage || !this.currentPage?.children) {
                return [];
            }

            return this.currentPage.children.filter(
                child => !this.availableLocalesNames.includes(child.breadcrumbTitle)
            );
        },
        showCities() {
            return this.currentPage && this.filteredPageChildren.length > 0;
        },
    },
    created() {
        if (this.availableLocales.includes(this.$i18n.locale)) {
            this.currentPage = JSON.parse(JSON.stringify(this.cmsPage));
        }
    },
    methods: {
        replaceSpecialCharacter(string) {
            return string.replace(/[èéêë]/g, 'e');
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/sass/_variables.scss';

$tile-spacing: 16px;
$mobile-tile-spacing: 8px;

.popular-places {
    * {
        box-sizing: border-box;
    }

    .section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__cities-heading {
        @extend %heading-3;
    }

    &__cities-heading,
    .section__heading {
        margin-bottom: 24px;

        @include lg {
            margin-bottom: 32px;
        }
    }

    &__tiles {
        display: flex;
        flex-wrap: nowrap;
        margin-left: -$mobile-tile-spacing;
        margin-bottom: $mobile-tile-spacing;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include sm {
            flex-wrap: wrap;
            margin: -$tile-spacing;
            margin-bottom: $tile-spacing;
        }

        .popular-place__tiles--no-bottom-margin {
            margin-bottom: 0;
        }
    }

    &__tile {
        display: flex;
        height: 250px + ($tile-spacing * 2);
        flex: 0 0 80%;
        max-width: 80%;
        padding: $mobile-tile-spacing;
        scroll-snap-align: start;
        box-sizing: border-box;

        a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background-size: cover;
            color: $white;
            text-decoration: none;
            font-weight: bold;
            padding-bottom: 32px;
            border-radius: $default-border-radius;
            @include font-size(20px);
        }

        @include sm {
            flex: 0 0 calc(100% / 2);
            max-width: calc(100% / 2);
            padding: $tile-spacing;

            &.popular-places__tile--columns-2 {
                flex: 0 0 100%;
                max-width: 100%;

                a {
                    background-position: center;
                }
            }
        }

        @include lg {
            flex: 0 0 calc(100% / 3);
            max-width: calc(100% / 3);

            &.popular-places__tile--columns-2 {
                flex: 0 0 calc(100% / 3 * 2);
                max-width: calc(100% / 3 * 2);
            }
        }
    }

    &__cities-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 10px;
    }

    &__cities-column {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: left;
        line-height: 1.5;
        color: $primary;

        @include lg {
            flex: 0 0 16.6667%;
            max-width: 16.6667%;
        }
    }
}
</style>
